const API_URL = process.env.REACT_APP_API_URL

const AWS_AUTH_CONFIG = {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
}

const PAGE_SIZE = 10

const COLORS = [
    'Blue',
    'Blue CAB',
    'Chrysoberyl Cats Eye',
    'Teal',
    'Yellow/Green',
    'Pink',
    'Purple',
    'Orange',
    'White',
    'Yellow',
    'Padparadscha',
    'Peach',
    'CC'
]
const WEIGHTS = [0.1, 0.5, 1.0, 5.0]

const NO_MEDIA_PLACEHOLDER = '/assets/img/stones/no-media.png'

export {
    AWS_AUTH_CONFIG,
    API_URL,
    PAGE_SIZE,
    COLORS,
    WEIGHTS,
    NO_MEDIA_PLACEHOLDER
}
