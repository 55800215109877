import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SnackbarContext } from 'context/SnackbarContext'

import { Link as RouterLink } from 'react-router-dom'

import {
    Breadcrumbs,
    Box,
    Button,
    Typography,
    Paper,
    Container,
    LinearProgress
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import useStyles from './StoneAdd.styles'

import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import axios from 'utils/axios'

import { useValidator } from 'components/stone/StoneFormValidator'
import StoneForm from 'components/stone/StoneForm'

const DEFAULT_STONE = {
    uuid: '',
    color: '',
    shape: '',
    dimension: '',
    weight: '',
    treatment: '',
    origin: '',
    rate: '',
    case: '',
    location: '',
    primaryMedia: '',
    medias: ''
}

const StoneAdd = () => {
    const history = useHistory()
    const classes = useStyles()
    const { showMessage } = useContext(SnackbarContext)

    const validator = useValidator()
    const [stone, setStone] = useState(DEFAULT_STONE)

    const [saving, setSaving] = useState(false)

    const handleFormSubmit = e => {
        e.preventDefault()

        if (!validator.validate(stone)) return false

        const payload = { ...stone }
        payload.medias = payload.medias.split('\n').filter(media => media)

        setSaving(true)
        axios
            .post(`/stones`, snakecaseKeys(payload, { deep: true }))
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })
                showMessage('A new stone has been added successfully.', 'success')
                history.push(`/stones/${data.uuid}`)
            })
            .catch(e => {
                showMessage('Failed to add a new stone.', 'error')
            })
            .finally(() => {
                setSaving(false)
            })

        return true
    }

    const Actions = () => (
        <Box className={classes.actions}>
            <Button variant="contained" type="submit" color="primary" disabled={saving}>
                Save
            </Button>
            <Box ml={1}>
                <Button variant="contained" component={RouterLink} to={{ pathname: '/stones' }}>
                    Cancel
                </Button>
            </Box>
        </Box>
    )

    return (
        <>
            <Box>
                <Typography variant={'h3'} gutterBottom>
                    Add Stone
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumbs"
                >
                    <RouterLink color="inherit" variant={'subtitle2'} to="/">
                        Dashboard
                    </RouterLink>
                    <RouterLink color="inherit" variant={'subtitle2'} to="/stones">
                        Stones
                    </RouterLink>
                    <Typography color="textPrimary" variant={'subtitle2'}>
                        Add Stone
                    </Typography>
                </Breadcrumbs>
            </Box>

            <Box mt={4}>
                <Paper component="form" autoComplete="off" onSubmit={handleFormSubmit}>
                    <Container maxWidth="md">
                        <Actions />
                        {saving && <LinearProgress className={classes.progressBar} />}
                        <StoneForm stone={stone} setStone={setStone} validator={validator} />
                        {saving && <LinearProgress className={classes.progressBar} />}
                        <Actions />
                    </Container>
                </Paper>
            </Box>
        </>
    )
}

export default StoneAdd
