import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        width: 240
    }
}))

export default useStyles
