import { createMuiTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: colors.cyan[200],
            main: colors.cyan[500],
            dark: colors.cyan[700],
            contrastText: '#fff'
        },
        // secondary: {},
        background: {
            paper: '#fff',
            default: '#fafafa'
            // image: colors.cyan[100]
        }
        // text: {}
    },
    // shape: {},
    // shadows,
    typography,
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0
                },
                html: {
                    '-webkit-font-smoothing': 'antialiased',
                    '-moz-osx-font-smoothing': 'grayscale',
                    height: '100%',
                    width: '100%'
                },
                body: {
                    height: '100%',
                    width: '100%'
                },
                a: {
                    color: colors.cyan[500],
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                },
                '#root': {
                    height: '100%',
                    width: '100%'
                }
            }
        },
        '.MuiButton-contained': {
            boxShadow: 'none'
        }
    }
})

theme.props = {
    MuiPaper: {
        elevation: 0
    },
    MuiButton: {
        disableElevation: true // no need to give this since the shadows is empty
    }
}

export default theme
