import { Grid, Box, Select, Input, MenuItem } from '@material-ui/core'
import FormControl from 'components/shared/FormControl/FormControl'

import { colors as colorOptions } from 'config/filterOptions'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const StoneLogForm = ({ stoneLog }) => {
    console.log(stoneLog)
    const stone = stoneLog.data
    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <FormControl name="uuid" label="UUID" readonly>
                        <Input name="uuid" value={stone.uuid} />
                    </FormControl>
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={6}>
                    <FormControl name="color" label="Color" readonly>
                        <Select name="color" value={stone.color} MenuProps={MenuProps}>
                            {colorOptions.map(color => (
                                <MenuItem key={color} value={color}>
                                    {color}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="shape" label="Shape" readonly>
                        <Input name="shape" value={stone.shape} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="dimension" label="Dimension" readonly>
                        <Input name="dimension" value={stone.dimension} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="weight" label="Weight" readonly>
                        <Input name="weight" value={stone.weight} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="treatment" label="Treatment">
                        <Input name="treatment" value={stone.treatment} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="origin" label="Origin" readonly>
                        <Input name="origin" value={stone.origin} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="rate" label="Rate" readonly>
                        <Input name="rate" value={stone.rate} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="case" label="Case" readonly>
                        <Input name="case" value={stone.case} />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl name="location" label="Location" readonly>
                        <Input name="location" value={stone.location} />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl name="primaryMedia" label="Primary Media" readonly>
                        <Input name="primaryMedia" value={stone.primaryMedia} />
                    </FormControl>
                </Grid>
                <Grid item md={12} readonly>
                    <FormControl
                        name="medias"
                        label="Medias"
                        helperText="URLs are separated by line break."
                    >
                        <Input name="medias" value={stone.medias} multiline rows={5} />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
export default StoneLogForm
