import { FormControl as FormFontrolBase, FormHelperText, InputLabel } from '@material-ui/core'

const FormControl = props => {
    let {name, label, helperText,error, children, ...otherProps} = props
    helperText = error ?? helperText

    return (
        <FormFontrolBase {...otherProps} error={error} fullWidth>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            {children}
            {helperText && <FormHelperText id={`${name}-helper-text`}>{helperText}</FormHelperText>}
        </FormFontrolBase>
    )
}

export default FormControl
