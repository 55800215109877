import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SnackbarContext } from 'context/SnackbarContext'

import { Link as RouterLink } from 'react-router-dom'

import {
    Breadcrumbs,
    Box,
    Button,
    Typography,
    Paper,
    Container,
    LinearProgress
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import useStyles from './StoneLogDetail.styles'

import camelcaseKeys from 'camelcase-keys'
import axios from 'utils/axios'

import StoneLogForm from 'components/stone-log/StoneLogForm'
import { useEffect } from 'react'

const StoneLogDetail = () => {
    const params = useParams()
    const classes = useStyles()
    const { showMessage } = useContext(SnackbarContext)

    const [stoneLog, setStoneLog] = useState(null)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const load = async () => {
            setLoading(true)
            axios
                .get(`/stone-logs/${params.id}`)
                .then(({ data }) => {
                    data = camelcaseKeys(data, { deep: true })
                    data.data.medias = data.data.medias ? data.data.medias.join('\n') : ''
                    setStoneLog(data)
                })
                .catch(e => {
                    showMessage('Failed to load stone log.', 'error')
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        load()
    }, [params.id])

    const Actions = () => (
        <Box className={classes.actions}>
            <Button variant="contained" component={RouterLink} to={{ pathname: '/stone-logs' }}>
                Back to Stone Logs
            </Button>
        </Box>
    )

    return (
        <>
            <Box>
                <Typography variant={'h3'} gutterBottom>
                    {stoneLog ? `${stoneLog.stoneUuid} - ${stoneLog.action}` : 'Stone Log Detail'}
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumbs"
                >
                    <RouterLink color="inherit" variant={'subtitle2'} to="/">
                        Dashboard
                    </RouterLink>
                    <RouterLink color="inherit" variant={'subtitle2'} to="/stone-logs">
                        Stone Logs
                    </RouterLink>
                    <Typography color="textPrimary" variant={'subtitle2'}>
                        Stone Log Detail
                    </Typography>
                </Breadcrumbs>
            </Box>

            {loading && <LinearProgress className={classes.progressBar} />}

            {!loading && stoneLog && (
                <Box mt={4}>
                    <Paper autoComplete="off">
                        <Container maxWidth="md">
                            <Actions />
                            <StoneLogForm stoneLog={stoneLog} />
                            <Actions />
                        </Container>
                    </Paper>
                </Box>
            )}
        </>
    )
}

export default StoneLogDetail
