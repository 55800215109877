import { useState } from 'react'
import {
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Input,
    IconButton,
    InputAdornment
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import useStyles from './Filter.styles'

import { colors as colorOptions, weights as weightOptions } from 'config/filterOptions'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const Filter = ({ onChange }) => {
    const classes = useStyles()

    const [colors, setColors] = useState([])
    const [weights, setWeights] = useState([])
    const [keyword, setKeyword] = useState('')

    const filter = { colors, weights, keyword }

    const handleColorChange = newColors => {
        setColors(newColors)
        onChange({ ...filter, colors: newColors })
    }
    const handleWeightChange = newWeights => {
        setWeights(newWeights)
        onChange({ ...filter, weights: newWeights })
    }
    const handleKeywordChange = newKeyword => {
        setKeyword(newKeyword)
    }
    const handleSearchFormSubmit = e => {
        e.preventDefault()
        onChange(filter)
    }

    return (
        <Paper
            component="form"
            onSubmit={handleSearchFormSubmit}
            className={classes.form}
        >
            <FormControl className={classes.formControl}>
                <InputLabel id="colors-select-label">Colors</InputLabel>
                <Select
                    labelId="colors-select-label"
                    id="colors-select"
                    multiple
                    value={colors}
                    onChange={event => handleColorChange(event.target.value)}
                    input={<Input />}
                    MenuProps={MenuProps}
                    renderValue={selected => selected.join(', ')}
                >
                    {colorOptions.map(color => (
                        <MenuItem key={color} value={color}>
                            <Checkbox checked={colors.indexOf(color) > -1} />
                            <ListItemText primary={color} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="weight-select-label">Weight</InputLabel>
                <Select
                    labelId="weight-select-label"
                    id="weight-select"
                    multiple
                    value={weights}
                    onChange={event => handleWeightChange(event.target.value)}
                    input={<Input />}
                    MenuProps={MenuProps}
                    renderValue={selected => selected.map(w => w.join('~')).join(', ')}
                >
                    {weightOptions.map(weight => (
                        <MenuItem key={weight} value={weight}>
                            <Checkbox checked={weights.indexOf(weight) > -1} />
                            <ListItemText primary={weight.join('~')} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel>Keyword</InputLabel>
                <Input
                    placeholder="Search with keyword"
                    value={keyword}
                    onChange={event => handleKeywordChange(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton type="submit">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Paper>
    )
}

export default Filter
