import { COLORS, WEIGHTS } from './constants'

const weightSpans = [null, ...WEIGHTS].reduce((weightSpans, weight, index, weights) => {
    weightSpans.push([weight, index < weights.length - 1 ? weights[index + 1] : null])

    return weightSpans
}, [])

const colors = COLORS
const weights = weightSpans

export { colors, weights }
