import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Main from './layouts/Main'
import RouteWithLayout from './components/shared/RouteWithLayout'

import Login from 'pages/Login'
import { StoneList, StoneEdit, StoneAdd } from './pages/stone'
import { StoneLogList, StoneLogDetail } from './pages/stone-log'

const AppRouter = () => (
    <Switch>
        <Route exact path="/login" component={Login} />

        {/* <RouteWithLayout layout={Main} exact path="/" redirect="/stones" /> */}
        <Route exact path="/">
            <Redirect to="stones" />
        </Route>

        <RouteWithLayout layout={Main} exact path="/stones" component={StoneList} />
        <RouteWithLayout layout={Main} exact path="/stones/add" component={StoneAdd} />
        <RouteWithLayout layout={Main} exact path="/stones/:id" component={StoneEdit} />
        <RouteWithLayout layout={Main} exact path="/stone-logs" component={StoneLogList} />
        <RouteWithLayout layout={Main} exact path="/stone-logs/:id" component={StoneLogDetail} />
    </Switch>
)

export default AppRouter
