import { Link as RouterLink } from 'react-router-dom'

import { Breadcrumbs, Box, Button, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import useStyles from './StoneList.styles'

import StoneListTable from 'components/stone/StoneListTable'

const StoneList = () => {
    const classes = useStyles()

    return (
        <>
            <Box className={classes.header}>
                <div>
                    <Typography variant={'h3'} gutterBottom>
                        Stones
                    </Typography>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumbs"
                    >
                        <RouterLink color="inherit" variant={'subtitle2'} to="/">
                            Dashboard
                        </RouterLink>
                        <Typography color="textPrimary" variant={'subtitle2'}>
                            Stones
                        </Typography>
                    </Breadcrumbs>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.stoneAddButton}
                    component={RouterLink}
                    to={{
                        pathname: `/stones/add`
                    }}
                >
                    Add Stone
                </Button>
            </Box>

            <Box mt={4}>
                <StoneListTable />
            </Box>
        </>
    )
}

export default StoneList
