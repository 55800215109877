import { useState } from 'react'
import {
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Input,
    IconButton,
    InputAdornment
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import useStyles from './Filter.styles'

import { colors as colorOptions, weights as weightOptions } from 'config/filterOptions'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const ACTION_OPTIONS = ['CREATED', 'UPDATED', 'ORDERED' /* 'SOLD' */, 'ARCHIVED']

const Filter = ({ onChange }) => {
    const classes = useStyles()

    const [action, setAction] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [keyword, setKeyword] = useState('')

    const filter = { action, from, to, keyword }

    const handleActionChange = action => {
        setAction(action)
        onChange({ ...filter, action })
    }
    const handleFromChange = from => {
        setFrom(from)
        onChange({ ...filter, from })
    }
    const handleToChange = to => {
        setTo(to)
        onChange({ ...filter, to })
    }
    const handleKeywordChange = keyword => {
        setKeyword(keyword)
    }
    const handleSearchFormSubmit = e => {
        e.preventDefault()
        onChange(filter)
    }

    return (
        <Paper
            component="form"
            onSubmit={handleSearchFormSubmit}
            className={classes.form}
        >
            <FormControl className={classes.formControl}>
                <InputLabel shrink id="action-select-label">Action</InputLabel>
                <Select
                    labelId="action-select-label"
                    value={action}
                    onChange={event => handleActionChange(event.target.value)}
                    MenuProps={MenuProps}
                    displayEmpty

                >
                    <MenuItem value="">All</MenuItem>
                    {ACTION_OPTIONS.map(action => (
                        <MenuItem key={action} value={action}>
                            {action}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel>Keyword</InputLabel>
                <Input
                    placeholder="Search with keyword"
                    value={keyword}
                    onChange={event => handleKeywordChange(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton type="submit">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Paper>
    )
}

export default Filter
