import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'

import useStyles from './StoneListTable.styles'

import { SnackbarContext } from 'context'
import { PAGE_SIZE } from 'config/constants'
import axios from 'utils/axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    ButtonGroup,
    Button,
    Checkbox
} from '@material-ui/core'
import Filter from './Filter'
import StoneArchiveConfirmDialog from '../StoneArchiveConfirmDialog'

const StoneListTable = () => {
    const history = useHistory()
    const classes = useStyles()

    const { showMessage } = useContext(SnackbarContext)

    const [loading, setLoading] = useState(false)
    const [stones, setStones] = useState([])
    const [offset, setOffset] = useState(0)

    // const STONES_LOADER_DEBOUNCE_TIMEOUT = 200
    const [stonesLoaderDebounceHandler, setStonesLoaderDebounceHandler] = useState(null)
    const [stonesLoaderRequestSource, setStonesLoaderRequestSource] = useState(null)

    const [filter, setFilter] = useState(null)

    const [selectedStones, setSelectedStones] = useState([])

    const selectedStoneCount = selectedStones.length
    const isSelectedAll = selectedStoneCount > 0 && selectedStoneCount >= stones.length

    const [archiving, setArchiving] = useState(false)
    const [archivingStones, setArchivingStones] = useState([])
    const [openArchiveConfirmDialog, setOpenArchiveConfirmDialog] = useState(false)

    useEffect(() => {
        loadStones()
    }, [filter, offset])

    const createSearchParams = () => {
        const colors = filter?.colors?.join(',')
        const weights = filter?.weights?.map(w => w.join(':')).join(',')
        const keyword = filter?.keyword
        const params = {
            limit: PAGE_SIZE,
            offset,
            colors,
            weights,
            keyword
        }

        return params
    }
    const loadStones = () => {
        const params = createSearchParams()

        clearSelectedStones()
        setLoading(true)

        const source = axios.CancelToken.source()
        setStonesLoaderRequestSource(source)

        axios
            .get(`/stones`, { params, cancelToken: source.token })
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })

                setStones(data)

                finishStonesLoading()
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    console.log(e)
                    showMessage('Failed to load the stones.', 'error')

                    finishStonesLoading()
                }
            })
    }

    const cancelStonesLoading = () => {
        if (stonesLoaderRequestSource) {
            stonesLoaderRequestSource.cancel(
                'The request has been cancelled due to another new request'
            )
        }
        if (stonesLoaderDebounceHandler) {
            clearTimeout(stonesLoaderDebounceHandler)
            setStonesLoaderDebounceHandler(null)
        }

        finishStonesLoading()
    }

    const finishStonesLoading = () => {
        setStonesLoaderRequestSource(null)
        setLoading(false)
    }

    const handleFilterChange = filter => {
        cancelStonesLoading()

        setOffset(0)
        setStones([])
        setFilter(filter)
    }

    const handleStoneClick = id => {
        history.push(`/stones/${id}`)
    }

    const loadPrev = () => {
        setOffset(offset - PAGE_SIZE)
    }
    const loadNext = () => {
        setOffset(offset + PAGE_SIZE)
    }

    const archive = () => {
        setArchiving(true)
        axios
            .post(
                `/stones/archive`,
                snakecaseKeys(
                    archivingStones.map(({ uuid }) => uuid),
                    { deep: true }
                )
            )
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })
                let msg
                if (archivingStones.length > 1) {
                    msg = `${selectedStoneCount} stones have been archived successfully.`
                } else {
                    msg = `The stone ${archivingStones[0].uuid} has been archived successfully.`
                }
                showMessage(msg, 'success')
                loadStones()
            })
            .catch(e => {
                showMessage('Failed to archive.', 'error')
            })
            .finally(() => {
                setArchiving(false)
                setArchivingStones([])
                setOpenArchiveConfirmDialog(false)
            })
    }

    const confirmArchive = stones => {
        setArchivingStones(stones)
        setOpenArchiveConfirmDialog(true)
    }

    const handleArchiveClick = (e, stone) => {
        e.stopPropagation()
        confirmArchive([stone])
    }

    const handleArchiveSelectedClick = () => {
        confirmArchive(selectedStones)
    }

    const handleSelectClick = stone => {
        const selectedIndex = selectedStones.indexOf(stone)
        let newSelectedStones = []

        if (selectedIndex === -1) {
            newSelectedStones = newSelectedStones.concat(selectedStones, stone)
        } else if (selectedIndex === 0) {
            newSelectedStones = newSelectedStones.concat(selectedStones.slice(1))
        } else if (selectedIndex === selectedStones.length - 1) {
            newSelectedStones = newSelectedStones.concat(selectedStones.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelectedStones = newSelectedStones.concat(
                selectedStones.slice(0, selectedIndex),
                selectedStones.slice(selectedIndex + 1)
            )
        }

        setSelectedStones(newSelectedStones)
    }

    const handleSelectAllClick = e => {
        if (e.target.checked) {
            setSelectedStones([...stones])
            return
        }

        clearSelectedStones()
    }

    const clearSelectedStones = () => {
        setSelectedStones([])
    }

    const columns = [
        { name: 'id', title: 'ID' },
        { name: 'color', title: 'Color' },
        { name: 'shape', title: 'Shape' },
        { name: 'dimension', title: 'Dimension' },
        { name: 'weight', title: 'Weight' },
        { name: 'rate', title: 'Rate' },
        // { name: 'treatment', title: 'Treatment' },
        // { name: 'origin', title: 'Origin' }
        { name: 'author', title: 'Author' },
        { name: 'action', title: 'Action' }
    ]

    return (
        <>
            <Paper className={classes.filterContainer}>
                <Filter onChange={handleFilterChange} />
                {selectedStoneCount > 0 && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleArchiveSelectedClick}
                    >
                        Archive {selectedStoneCount} stone(s)
                    </Button>
                )}
            </Paper>

            <TableContainer component={Paper}>
                <Table aria-label="Stone List Table">
                    <TableHead>
                        <TableRow padding="checkbox">
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedStoneCount > 0 && !isSelectedAll}
                                    checked={isSelectedAll}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all stones' }}
                                />
                            </TableCell>
                            {columns.map(header => (
                                <TableCell key={header.name}>{header.title}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stones.map(stone => (
                            <TableRow
                                className={classes.tableRow}
                                key={`stone-${stone.uuid}`}
                                onClick={() => handleStoneClick(stone.uuid)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedStones.includes(stone)}
                                        onClick={e => e.stopPropagation()}
                                        onChange={() => handleSelectClick(stone)}
                                    />
                                </TableCell>
                                <TableCell>{stone.uuid}</TableCell>
                                <TableCell>{stone.color}</TableCell>
                                <TableCell>{stone.shape}</TableCell>
                                <TableCell>{stone.dimension}</TableCell>
                                <TableCell>{stone.weight}ct</TableCell>
                                <TableCell>${stone.rate}</TableCell>
                                {/* <TableCell>{stone.treatment}</TableCell>
                                <TableCell>{stone.origin}</TableCell> */}
                                <TableCell>
                                    {stone.updatedByName} / {stone.updatedAt}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={e => handleArchiveClick(e, stone)}
                                    >
                                        Archive
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Paper className={classes.pagination}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button disabled={loading || !offset} onClick={loadPrev}>
                        Prev
                    </Button>
                    <Button disabled={loading || stones.length < PAGE_SIZE} onClick={loadNext}>
                        Next
                    </Button>
                </ButtonGroup>
            </Paper>
            {loading && <LinearProgress className={classes.progressBar} />}

            <StoneArchiveConfirmDialog
                open={openArchiveConfirmDialog}
                setOpen={setOpenArchiveConfirmDialog}
                onConfirm={archive}
                stones={archivingStones}
                archiving={archiving}
            />
        </>
    )
}

export default StoneListTable
