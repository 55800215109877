import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    filterContainer: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(2)
    },
    tableRow: {
        cursor: 'pointer'
    },
    pagination: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))

export default useStyles
