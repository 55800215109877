import { Link as RouterLink } from 'react-router-dom'

import { Breadcrumbs, Box, Button, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import useStyles from './StoneLogList.styles'

import StoneLogListTable from 'components/stone-log/StoneLogListTable'

const StoneLogList = () => {
    const classes = useStyles()

    return (
        <>
            <Box>
                <Typography variant={'h3'} gutterBottom>
                    Stone Logs
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumbs"
                >
                    <RouterLink color="inherit" variant={'subtitle2'} to="/">
                        Dashboard
                    </RouterLink>
                    <Typography color="textPrimary" variant={'subtitle2'}>
                        Stone Logs
                    </Typography>
                </Breadcrumbs>
            </Box>

            <Box mt={4}>
                <StoneLogListTable />
            </Box>
        </>
    )
}

export default StoneLogList
