import { Auth, Hub } from 'aws-amplify'
import { useHistory, useLocation } from 'react-router'

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
    PieChart as DashbaordIcon,
    Business as BusinessIcon,
    AccessTime as LogIcon,
    ExitToApp as SignOutIcon
} from '@material-ui/icons'

import useStyles from './NavContent.styles'
import { useEffect, useState } from 'react'

const NavContent = () => {
    const [navItems, setNavItems] = useState([])

    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const items = [
            {
                title: 'Dashboard',
                icon: <DashbaordIcon fontSize="small" color={'inherit'} />,
                path: '/',
                exact: true,
                disabled: true
            },
            {
                title: 'Stones',
                icon: <BusinessIcon fontSize="small" color={'inherit'} />,
                path: '/stones'
            },
            {
                title: 'Stone Logs',
                icon: <LogIcon fontSize="small" color={'inherit'} />,
                path: '/stone-logs'
            },
            {
                title: 'Logout',
                icon: <SignOutIcon fontSize="small" color={'inherit'} />,
                onClick: () => {
                    Auth.signOut()
                    return false
                }
            }
        ]

        setNavItems(items)
    }, [])

    Hub.listen('auth', data => {
        switch (data.payload.event) {
            case 'signOut':
                history.push('/login')
                break
            default:
                break
        }
    })

    const goTo = path => {
        history.push(path)
        return false
    }

    const classes = useStyles()

    const active = (exact, path) => {
        return (
            (exact && location.pathname === path) || (!exact && location.pathname.startsWith(path))
        )
    }

    return (
        <List classes={{ root: classes.list }}>
            {navItems.map(({ title, icon, path, exact, onClick, disabled }, i) => {
                return (
                    <ListItem
                        button
                        key={`nav-item-${i}`}
                        onClick={
                            onClick
                                ? e => {
                                      e.preventDefault()
                                      onClick(e)
                                  }
                                : path
                                ? e => {
                                      e.preventDefault()
                                      goTo(path)
                                  }
                                : null
                        }
                        selected={active(exact, path)}
                        classes={{ root: classes.root, selected: classes.selected }}
                        disabled={disabled}
                    >
                        <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
                        <ListItemText classes={{ primary: classes.listItemText }} primary={title} />
                    </ListItem>
                )
            })}
        </List>
    )
}

export default NavContent
