import { Grid, Box, Select, Input, MenuItem } from '@material-ui/core'
import FormControl from 'components/shared/FormControl/FormControl'

import { colors as colorOptions } from 'config/filterOptions'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const StoneForm = ({ stone, setStone, validator }) => {
    const { validate, errors } = validator

    const handleInputChange = e => {
        const { name, value } = e.target
        setStone({
            ...stone,
            [name]: value
        })

        validate({ [name]: value })
    }

    const getError = name => {
        const error = errors[name]
        return error && error.length ? error[0] : null
    }

    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <FormControl name="uuid" label="UUID" error={getError('uuid')}>
                        <Input name="uuid" value={stone.uuid} onChange={handleInputChange} />
                    </FormControl>
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={6}>
                    <FormControl name="color" label="Color" error={getError('color')}>
                        <Select
                            name="color"
                            value={stone.color}
                            MenuProps={MenuProps}
                            onChange={handleInputChange}
                        >
                            {colorOptions.map(color => (
                                <MenuItem key={color} value={color}>
                                    {color}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="shape" label="Shape" error={getError('shape')}>
                    <Input
                            name="shape"
                            value={stone.shape}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="dimension" label="Dimension" error={getError('dimension')}>
                        <Input
                            name="dimension"
                            value={stone.dimension}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="weight" label="Weight" error={getError('weight')}>
                        <Input name="weight" value={stone.weight} onChange={handleInputChange} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="treatment" label="Treatment" error={getError('treatment')}>
                        <Input
                            name="treatment"
                            value={stone.treatment}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="origin" label="Origin" error={getError('origin')}>
                        <Input name="origin" value={stone.origin} onChange={handleInputChange} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="rate" label="Rate" error={getError('rate')}>
                        <Input name="rate" value={stone.rate} onChange={handleInputChange} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl name="case" label="Case">
                        <Input name="case" value={stone.case} onChange={handleInputChange} />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl name="location" label="Location">
                        <Input
                            name="location"
                            value={stone.location}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl name="primaryMedia" label="Primary Media">
                        <Input
                            name="primaryMedia"
                            value={stone.primaryMedia}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl
                        name="medias"
                        label="Medias"
                        helperText="URLs are separated by line break."
                    >
                        <Input
                            name="medias"
                            value={stone.medias}
                            multiline
                            rows={5}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
export default StoneForm
