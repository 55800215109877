import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import styled from 'styled-components'
import Layout, {
    Root,
    getDrawerSidebar,
    getSidebarTrigger,
    getSidebarContent,
    getCollapseBtn,
    getContent,
    getFooter
} from '@mui-treasury/layout'

import HeaderContent from './components/HeaderContent'
import FooterContent from './components/FooterContent'

import NavContent from './components/NavContent'

import useStyles from './Main.styles'
import { Box } from '@material-ui/core'
import AppHeader from './components/AppHeader'

import theme from 'theme'

const DrawerSidebar = getDrawerSidebar(styled)
const SidebarTrigger = getSidebarTrigger(styled)
const SidebarContent = getSidebarContent(styled)
const CollapseBtn = getCollapseBtn(styled)
const Content = getContent(styled)
const Footer = getFooter(styled)

const scheme = Layout()

scheme.configureHeader(builder => {
    builder
        .registerConfig('xs', {
            position: 'sticky'
        })
        .registerConfig('md', {
            position: 'relative' // won't stick to top when scroll down
        })
})

scheme.configureEdgeSidebar(builder => {
    builder
        .create('mainSidebar', { anchor: 'left' })
        .registerTemporaryConfig('xs', {
            anchor: 'left',
            width: 'auto' // 'auto' is only valid for temporary variant
        })
        .registerPermanentConfig('md', {
            width: 256, // px, (%, rem, em is compatible)
            // collapsible: true,
            collapsedWidth: 64
        })
})

scheme.enableAutoCollapse('mainSidebar', 'md')

const Main = props => {
    const { children } = props

    const classes = useStyles()

    return (
        <Root theme={theme} scheme={scheme} classes={classes.root}>
            {({ state: { sidebar } }) => (
                <Box className={classes.rootBox}>
                    <AppHeader>
                        <HeaderContent
                            sidebarTrigger={
                                <SidebarTrigger
                                    sidebarId="mainSidebar"
                                    className={classes.sidebarTrigger}
                                />
                            }
                        />
                    </AppHeader>

                    <DrawerSidebar sidebarId="mainSidebar">
                        <Toolbar />

                        <SidebarContent>
                            <NavContent />
                        </SidebarContent>
                        <CollapseBtn />
                    </DrawerSidebar>
                    <Content className={classes.wrapper}>
                        <Box className={classes.container}>
                            <Box className={classes.content}>{children}</Box>
                        </Box>
                    </Content>
                    <Footer>
                        <FooterContent />
                    </Footer>
                </Box>
            )}
        </Root>
    )
}

export default Main
