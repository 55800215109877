import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SnackbarContext } from 'context/SnackbarContext'

import { Link as RouterLink } from 'react-router-dom'

import {
    Breadcrumbs,
    Box,
    Button,
    Typography,
    Paper,
    Container,
    LinearProgress
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import useStyles from './StoneEdit.styles'

import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import axios from 'utils/axios'

import { useValidator } from 'components/stone/StoneFormValidator'
import StoneForm from 'components/stone/StoneForm'
import { useEffect } from 'react'

import StoneArchiveConfirmDialog from 'components/stone/StoneArchiveConfirmDialog'

const StoneEdit = () => {
    const history = useHistory()
    const params = useParams()
    const classes = useStyles()
    const { showMessage } = useContext(SnackbarContext)

    const validator = useValidator()
    const [stone, setStone] = useState(null)

    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)

    const [archiving, setArchiving] = useState(false)
    const [openArchiveConfirmDialog, setOpenArchiveConfirmDialog] = useState(false)

    useEffect(() => {
        const load = async () => {
            setLoading(true)
            axios
                .get(`/stones/${params.id}`)
                .then(({ data }) => {
                    data = camelcaseKeys(data, { deep: true })
                    data.medias = data.medias ? data.medias.join('\n') : ''
                    setStone(data)
                })
                .catch(e => {
                    showMessage('Failed to load stone.', 'error')
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        load()
    }, [params.id])

    const handleFormSubmit = e => {
        e.preventDefault()

        if (!validator.validate(stone)) return false

        const payload = { ...stone }
        payload.medias = payload.medias.split('\n').filter(media => media)

        setSaving(true)
        axios
            .patch(`/stones/${payload.uuid}`, snakecaseKeys(payload, { deep: true }))
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })
                data.medias = data.medias ? data.medias.join('\n') : ''
                setStone(data)
                showMessage('The stone has been updated successfully.', 'success')
            })
            .catch(e => {
                showMessage('Failed to update the stone.', 'error')
            })
            .finally(() => {
                setSaving(false)
            })

        return true
    }

    const archive = () => {
        setArchiving(true)
        axios
            .post(`/stones/archive`, snakecaseKeys([stone.uuid], { deep: true }))
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })
                showMessage(`The stone ${stone.uuid} has been archived successfully.`, 'success')
                history.push(`/stones`)
            })
            .catch(e => {
                showMessage('Failed to archive.', 'error')
            })
            .finally(() => {
                setArchiving(false)
                setOpenArchiveConfirmDialog(false)
            })
    }

    const handleArchiveClick = (e, stone) => {
        setOpenArchiveConfirmDialog(true)
    }

    const Actions = () => (
        <Box className={classes.actions}>
            <Button
                variant="contained"
                type="button"
                color="secondary"
                disabled={saving}
                onClick={handleArchiveClick}
            >
                Archive
            </Button>
            <Box ml={1}>
                <Button variant="contained" type="submit" color="primary" disabled={saving}>
                    Save
                </Button>
            </Box>
            <Box ml={1}>
                <Button variant="contained" component={RouterLink} to={{ pathname: '/stones' }}>
                    Cancel
                </Button>
            </Box>
        </Box>
    )

    return (
        <>
            <Box>
                <Typography variant={'h3'} gutterBottom>
                    Edit Stone
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumbs"
                >
                    <RouterLink color="inherit" variant={'subtitle2'} to="/">
                        Dashboard
                    </RouterLink>
                    <RouterLink color="inherit" variant={'subtitle2'} to="/stones">
                        Stones
                    </RouterLink>
                    <Typography color="textPrimary" variant={'subtitle2'}>
                        Edit Stone
                    </Typography>
                </Breadcrumbs>
            </Box>

            {loading && <LinearProgress className={classes.progressBar} />}

            {!loading && stone && (
                <Box mt={4}>
                    <Paper component="form" autoComplete="off" onSubmit={handleFormSubmit}>
                        <Container maxWidth="md">
                            <Actions />
                            {saving && <LinearProgress className={classes.progressBar} />}
                            <StoneForm stone={stone} setStone={setStone} validator={validator} />
                            {saving && <LinearProgress className={classes.progressBar} />}
                            <Actions />
                        </Container>
                    </Paper>
                </Box>
            )}

            <StoneArchiveConfirmDialog
                open={openArchiveConfirmDialog}
                setOpen={setOpenArchiveConfirmDialog}
                onConfirm={archive}
                stones={[stone]}
                archiving={archiving}
            />
        </>
    )
}

export default StoneEdit
