import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'between',
        alignItems: 'flex-end'
    },
    stoneAddButton: {
        marginLeft: 'auto',
        height: '36px'
    },
}))

export default useStyles
