import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'

import useStyles from './StoneLogListTable.styles'

import { SnackbarContext } from 'context'
import { PAGE_SIZE } from 'config/constants'
import axios from 'utils/axios'
import camelcaseKeys from 'camelcase-keys'

import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    ButtonGroup,
    Button
} from '@material-ui/core'
import { GetApp as ExportIcon } from '@material-ui/icons'
import Filter from './Filter'
import { format } from 'date-fns'

const StoneLogListTable = () => {
    const history = useHistory()
    const classes = useStyles()

    const { showMessage } = useContext(SnackbarContext)

    const [loading, setLoading] = useState(false)
    const [stoneLogs, setstoneLogs] = useState([])
    const [offset, setOffset] = useState(0)

    // const stoneLogs_LOADER_DEBOUNCE_TIMEOUT = 200
    const [stoneLogsLoaderDebounceHandler, setstoneLogsLoaderDebounceHandler] = useState(null)
    const [stoneLogsLoaderRequestSource, setstoneLogsLoaderRequestSource] = useState(null)

    const [filter, setFilter] = useState(null)

    const [exporting, setExporting] = useState(false)

    useEffect(() => {
        loadstoneLogs()
    }, [filter, offset])

    const createSearchParams = () => {
        const action = filter?.action
        const from = filter?.from
        const to = filter?.to
        const keyword = filter?.keyword
        const params = {
            action,
            from,
            to,
            keyword
        }

        return params
    }
    const loadstoneLogs = () => {
        const params = createSearchParams()

        setLoading(true)

        const source = axios.CancelToken.source()
        setstoneLogsLoaderRequestSource(source)

        axios
            .get(`/stone-logs`, { params, cancelToken: source.token })
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })

                setstoneLogs(data)

                finishstoneLogsLoading()
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    console.log(e)
                    showMessage('Failed to load the stone logs.', 'error')

                    finishstoneLogsLoading()
                }
            })
    }

    const cancelstoneLogsLoading = () => {
        if (stoneLogsLoaderRequestSource) {
            stoneLogsLoaderRequestSource.cancel(
                'The request has been cancelled due to another new request'
            )
        }
        if (stoneLogsLoaderDebounceHandler) {
            clearTimeout(stoneLogsLoaderDebounceHandler)
            setstoneLogsLoaderDebounceHandler(null)
        }

        finishstoneLogsLoading()
    }

    const finishstoneLogsLoading = () => {
        setstoneLogsLoaderRequestSource(null)
        setLoading(false)
    }

    const handleFilterChange = filter => {
        cancelstoneLogsLoading()

        setOffset(0)
        setstoneLogs([])
        setFilter(filter)
    }

    const handleStoneClick = id => {
        history.push(`/stone-logs/${id}`)
    }

    const loadPrev = () => {
        setOffset(offset - PAGE_SIZE)
    }
    const loadNext = () => {
        setOffset(offset + PAGE_SIZE)
    }

    const download = text => {
        const filNameParts = ['log']
        if (filter?.action) filNameParts.push(filter.action)
        filNameParts.push(format(new Date(), 'yyyyMMddHHmmss', new Date()))

        const fileName = filNameParts.join('-') + '.csv'

        const fileBlob = new Blob([text], { type: 'text/csv' })
        const fileURL = URL.createObjectURL(fileBlob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = fileURL
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    const handleExportClick = () => {
        const params = createSearchParams()

        setExporting(true)

        axios
            .get(`/stone-logs/export`, { params })
            .then(({ data }) => {
                download(data)
            })
            .catch(e => {
                console.log(e)
                showMessage('Failed to export the stone logs.', 'error')
            })
            .finally(() => {
                setExporting(false)
            })
    }

    const columns = [
        { name: 'uuid', title: 'Stone ID' },
        { name: 'color', title: 'Color' },
        { name: 'shape', title: 'Shape' },
        { name: 'weight', title: 'Weight' },
        { name: 'rate', title: 'Rate' },
        { name: 'action', title: 'Action' },
        { name: 'author', title: 'Author' }
    ]

    return (
        <>
            <Paper className={classes.filterContainer}>
                <Filter onChange={handleFilterChange} />
                <Button
                    color="default"
                    startIcon={<ExportIcon />}
                    onClick={handleExportClick}
                >
                    Export
                </Button>
            </Paper>
            <TableContainer component={Paper}>
                <Table aria-label="Stone Log List Table">
                    <TableHead>
                        <TableRow>
                            {columns.map(header => (
                                <TableCell key={header.name}>{header.title}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stoneLogs.map(stoneLog => (
                            <TableRow
                                className={classes.tableRow}
                                key={`stone-log-${stoneLog.id}`}
                                onClick={() => handleStoneClick(stoneLog.id)}
                            >
                                <TableCell>{stoneLog.data.uuid}</TableCell>
                                <TableCell>{stoneLog.data.color}</TableCell>
                                <TableCell>{stoneLog.data.shape}</TableCell>
                                <TableCell>{stoneLog.data.weight}ct</TableCell>
                                <TableCell>${stoneLog.data.rate}</TableCell>
                                <TableCell>{stoneLog.action}</TableCell>
                                <TableCell>
                                    {stoneLog.createdByName} / {stoneLog.createdAt}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Paper className={classes.pagination}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button disabled={loading || !offset} onClick={loadPrev}>
                        Prev
                    </Button>
                    <Button disabled={loading || stoneLogs.length < PAGE_SIZE} onClick={loadNext}>
                        Next
                    </Button>
                </ButtonGroup>
            </Paper>
            {loading && <LinearProgress className={classes.progressBar} />}
        </>
    )
}

export default StoneLogListTable
