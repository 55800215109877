import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '700px',
        margin: 'auto',
        textAlign: 'center',
        padding: '15px'
    }
}))

export default useStyles
