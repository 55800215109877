import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    actions: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(3)
    }
}))

export default useStyles
