import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grow,
    Button,
    Box,
    CircularProgress
} from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow in={true} ref={ref} {...props} />
})

const StoneArchiveConfirmDialog = ({ open, setOpen, onConfirm, stones, archiving }) => {
	const handleClose = (e, reason) => {
        setOpen(!!reason)
    }

    const handleConfirm = () => {
        onConfirm && onConfirm()
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="Confirm Archive"
            aria-describedby="Confirm Archive"
        >
            <DialogTitle>Confirm Archive</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {stones.length > 1
                        ? `Are you sure to archive the stones ${stones
                              .map(({ uuid }) => uuid)
                              .join(', ')}?`
                        : `Are you sure to archive the stone ${stones[0]?.uuid}?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {archiving && (
                    <Box mr={2}>
                        <CircularProgress size={20} />
                    </Box>
                )}
                <Button onClick={handleClose} color="secondary" disabled={archiving}>
                    No
                </Button>
                <Button onClick={handleConfirm} color="primary" disabled={archiving}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default StoneArchiveConfirmDialog
