import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    filterContainer: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(2)
    },
    tableRow: {
        cursor: 'pointer'
    },
    progress: {
        margin: theme.spacing(2),
        width: 'calc(100% - 32px)'
    },
    root: {
        margin: `${theme.spacing(2)}px 0px`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        '&>div': {
            paddingInlineStart: '12px',
            paddingInlineEnd: '60px',
            flexGrow: '1'
        },
        '&>.iconButton': {
            position: 'absolute',
            right: '12px'
        }
    },
    pagination: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))

export default useStyles
