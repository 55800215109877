import { useState } from 'react'
import validate from 'validate.js'
import filterObject from 'filter-obj'

validate.validators.presence.options = { allowEmpty: false }

const constraints = {
    uuid: {
        presence: true
    },
    color: {
        presence: true
    },
    shape: {
        presence: true
    },
    weight: {
        presence: true,
        numericality: true
    },
    rate: {
        presence: true,
        numericality: true
    }
}

export const useValidator = () => {
    const [errors, setErrors] = useState({})
    const [isValid, setValid] = useState(false)

    const validateForm = fieldValues => {
        if (!fieldValues) {
            throw new Error('Cannot perform validation on an empty object.')
        }

        const keys = Object.keys(fieldValues)
        const errs = validate(fieldValues, filterObject(constraints, keys)) ?? {}
        keys.forEach(key => {
            if (errs[key] !== undefined) return
            errs[key] = null
        })

        const newErrors = {
            ...errors,
            ...errs
        }
        setErrors(newErrors)

        const valid = Object.values(newErrors).every(x => !x)
        setValid(valid)

        return valid
    }


    return {
        errors,
        validate: validateForm,
        isValid
    }
}
