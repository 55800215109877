import { Hub } from 'aws-amplify'
import { useHistory } from 'react-router'

import { Box, Hidden, Toolbar } from '@material-ui/core'

import Avatar from './Avatar'
import Logo from './Logo'

import useStyles from './HeaderContent.styles'

const HeaderContent = props => {
    const history = useHistory()
    const classes = useStyles()

    Hub.listen('auth', data => {
        switch (data.payload.event) {
            case 'signOut':
                history.push('/login')
                break
            default:
                break
        }
    })

    return (
        <Toolbar className={classes.toolbar}>
            <Logo />

            <Box flexGrow={1} />

            <Avatar />

            <Hidden lgUp>{props.sidebarTrigger}</Hidden>
        </Toolbar>
    )
}

export default HeaderContent
